import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';

const baseUrlMapper = {
  beta: 'https://api.ronhan.com',
  development: 'https://api.ronhan.com',
  testing: 'https://test-api.ronhan.com',
  sit: 'https://test-api.ronhan.com',
  uat: 'https://uat-api.ronhan.com',
  production: 'https://api.onerway.com',
};

const apiKeyMapper = {
  beta: 'ChkotfJTPA7D8JvgysJJ8lylz9wmJusF',
  development: 'ChkotfJTPA7D8JvgysJJ8lylz9wmJusF',
  testing: 'HBfFTVjSQ4G16DXYdUqzRYGUDAOhFSCj',
  sit: 'HBfFTVjSQ4G16DXYdUqzRYGUDAOhFSCj',
  uat: 'QZUG1EeTQD1XoqwJnTPg20a2xurQYsCY',
  production: 'lu9OuATOj3IGAKJQDBv0Crx4EQ3dXfaY',
};

const apiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    apiKey: apiKeyMapper[process.env.NODE_ENV],
  },
});
class DeviceFingerprintManager {
  constructor() {
    this.deviceCode = null;
    this.deviceCodePromise = this.initDeviceCode();
  }

  // 初始化设备码
  async initDeviceCode() {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      this.deviceCode = result.visitorId;
      return this.deviceCode;
    } catch (error) {
      console.error('生成指纹码失败:', error);
      return null;
    }
  }

  // 获取设备码
  async getCode() {
    if (!this.deviceCode) {
      this.deviceCode = await this.deviceCodePromise;
    }
    return this.deviceCode;
  }

  // 发送设备码
  async send(params) {
    try {
      const code = await this.getCode();
      if (code) {
        // 字段名解释：https://confluence.ronhan.com/confluence/pages/viewpage.action?pageId=43035843
        const param = {
          ...params,
          d: code,
        };

        try {
          apiInstance.post(baseUrlMapper[process.env.NODE_ENV] + '/risk-engine/ec/ckt/v1/ublog', param);
        } catch (error) {

        }
      } else {
        console.log('设备码不存在，请生成或获取设备码');
      }
    } catch (error) {

    }
  }

  // 清空设备码
  clearDeviceCode() {
    this.deviceCode = null;
  }
}

const deviceFingerprintManager = new DeviceFingerprintManager();

export { deviceFingerprintManager };
